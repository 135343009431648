import Button from "material-ui/Button";
import Icon from "material-ui/Icon";
import IconButton from "material-ui/IconButton";
import Menu, { MenuItem } from "material-ui/Menu";
import { TableRow } from "material-ui/Table";
import common from "material-ui/colors/common";
import green from "material-ui/colors/green";
import indigo from "material-ui/colors/indigo";
import red from "material-ui/colors/red";
import moment from "moment";
import React from "react";

import OrderDeliveryDate from "./OrderDeliveryDate";
import { default as TableCell } from "./OrdersTableCell";

const { white } = common;

const basketsCountStyle = {
  fontWeight: "bold",
  color: indigo[500],
};

const boldStyle = {
  fontWeight: "bold",
};

const firstRowColumnStyle = {
  width: 30,
};

const commentRowColumnStyle = {
  maxWidth: "12vw",
};

const openButtonStyle = {
  width: 40,
  height: 40,
};

class OrdersListRow extends React.PureComponent {
  state = {
    copyMenu: false,
    anchorEl: undefined,
  };

  onCopyClick = (e) => {
    this.setState({
      copyMenu: true,
      anchorEl: e.currentTarget,
    });
  };

  onCopyMenuClose = (e) => {
    this.setState({
      copyMenu: false,
      anchorEl: undefined,
    });
  };

  onCopyToOrder = () => {
    this.props.onCopyToOrder(this.props.order);
  };

  onCopyToReturn = () => {
    this.props.onCopyToReturn(this.props.order);
  };

  onOpen = () => {
    this.props.onOpen(this.props.order);
  };

  getRowStyle() {
    const style = {};
    if (["N", "Z_F"].indexOf(this.props.order.STATUS_ID) !== -1) {
      style.backgroundColor = white;
    } else if (this.props.order.FULL_PRICE === 0) {
      style.backgroundColor = red[100];
    } else {
      style.backgroundColor = green.A100;
    }
    return style;
  }

  render() {
    const { order } = this.props;

    return (
      <TableRow style={this.getRowStyle()}>
        <TableCell style={firstRowColumnStyle}>
          <Button
            color="accent"
            fab
            onClick={this.onOpen}
            style={openButtonStyle}
          >
            <Icon className="material-icons">details</Icon>
          </Button>
        </TableCell>
        <TableCell>
          <strong>{order.ID}</strong>
          <br />
          <span style={basketsCountStyle}>{order.BASKETS_COUNT}</span>
          <br />
          {moment(order.DATE_UPDATE).format("DD.MM.YYYY HH:mm:ss")}
          <br />
          {moment(order.DATE_INSERT).format("DD.MM.YYYY HH:mm:ss")}
          <br />
        </TableCell>
        <TableCell>
          {order.PRICE_FORMATTED} руб.
          <br />
          {order.DISCOUNT_VALUE_FORMATTED} руб. (
          <span style={boldStyle}>{order.DISCOUNT_PERCENT} %</span>)<br />
          {order.DELIVERY_PRICE_FORMATTED} руб.
          <br />
          <strong>{order.FULL_PRICE_FORMATTED}</strong> руб.
        </TableCell>
        <TableCell>
          <OrderDeliveryDate order={order} />
          <br />
          <span className="order-datetime">
            {order.CB_DATETIME
              ? moment(order.CB_DATETIME).format("DD.MM.YYYY HH:mm:ss")
              : ""}
          </span>
        </TableCell>
        <TableCell style={commentRowColumnStyle}>
          {order.ADMIN_COMMENT}
        </TableCell>
        <TableCell style={commentRowColumnStyle}>{order.ADDRESS}</TableCell>
        <TableCell>
          {order.FIO}
          <br />
          {order.PHONE_NUMBERS?.map((phone) => (
            <span key={`${order.ID}${phone}`}>
              <br />
              {phone}
            </span>
          ))}
        </TableCell>
        <TableCell>
          <span style={boldStyle}>{order.STATUS_NAME}</span>
          <br />
          {parseInt(order.TYPE_ID, 10) !== 2 && (
            <span style={boldStyle}>
              {order.AGGREGATED_NAME} {order.DATE_AGGREGATED}
            </span>
          )}
          <br />
          <span style={boldStyle}>{order.TYPE_NAME}</span>
          <br />
          <span style={boldStyle}>{order.SOURCE_NAME}</span>
        </TableCell>
        <TableCell>
          <IconButton
            href={`/api/operator/order/bill?orderId=${order.ID}`}
            target="_blank"
          >
            <Icon className="material-icons">insert_drive_file</Icon>
          </IconButton>
          <IconButton onClick={this.onCopyClick}>
            <Icon className="material-icons">content_copy</Icon>
          </IconButton>
          <Menu
            open={this.state.copyMenu}
            anchorEl={this.state.anchorEl}
            onRequestClose={this.onCopyMenuClose}
          >
            <MenuItem onClick={this.onCopyToOrder}>
              Копировать в обычный заказ
            </MenuItem>
            <MenuItem onClick={this.onCopyToReturn}>
              Копировать в возврат
            </MenuItem>
          </Menu>
          <br />
          <br />
        </TableCell>
      </TableRow>
    );
  }
}

export default OrdersListRow;
