import { TableCell } from "material-ui/Table";
import React from "react";

class OrdersTableCell extends React.PureComponent {
  render() {
    const newProps = {
      ...this.props,
      style: {
        ...this.props.style,
        whiteSpace: "normal",
        padding: "0 8px",
        overflow: "clip",
      },
    };
    return <TableCell {...newProps}>{this.props.children}</TableCell>;
  }
}

export default OrdersTableCell;
